.wc-spinner:before {
    left: 1px;
    border-color: #4B2883;
    border-right-color: transparent;
}

.wc-spinner::before {
    border: 2px solid #ffffff;
}

.wc-spinner.black::before {
    border: 2px solid #000;
}

.wc-spinner::before {
    border-right: 2px solid transparent;
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 18px;
    height: 18px;
    animation: animation-spinner .5s linear infinite;
    right: 8px;
    top: calc(50% - 9px);
    border-radius: 50%;
    left: auto;
}

.wc-spinner {
    position: relative;
    padding-right: 30px !important;
}

.wc-custom-loading {
    min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes animation-spinner {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}