/*.error-message {
    background-color: #f8d7da;
    border: 1px solid #f1aeb5;
    border-left-width: 3px;
    border-radius: 5px;
    color: #58151c !important;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 8px;
    display: inline-block;
    margin: 5px 0 0;
    letter-spacing: 0.5px;
}*/

/*============================ login css ============================
============================ login css ============================*/
.wc-width-full {
    width: 90%;
}
.error-message {
	color: red !important;
	font-size: 14px;
	font-weight: 400;
	margin: 5px 0 0;
}

.bg-gradient-primary .login-wrap {
	max-width: 534px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
}


/*============================ header css ============================
============================ header css ============================*/
.disable-click{
	pointer-events: none;
}
.navbar-nav.active {
    margin-left: -6.5rem;
}
.navbar-nav {
    transition: 0.3s all ease;
}
.topbar #sidebarToggleTop:hover{
	background-color: transparent;
}
#sidebarToggleTop:focus-within,
#sidebarToggleTop:focus {
	box-shadow: none;
}

.navbar .select__control .select__indicators {
	background: #1e3038;
	border-radius: 0.35rem;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border: 1px solid transparent;
}

.navbar .select__control .select__indicators span.select__indicator-separator {
	display: none;
}

.navbar .select__control .select__value-container {
	background: #f8f9fc;
	border-radius: 0.35rem;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border: 0;
	height: auto;
	min-height: 38px;
	font-size: 0.85rem;
	color: #6e707e;
}

.navbar .select__single-value,
.navbar .select__menu {
	font-size: 0.85rem;
	color: #6e707e;
}

.navbar .select__control .select__indicators:hover .select__indicator {
	color: #fff;
}

.navbar .select__control .select__indicators,
.navbar .select__control .select__indicators:hover,
.navbar .select__control:hover,
.navbar .select__control {
	border: 0 !important;
	box-shadow: none;
}

.navbar .select__control.select__control--is-focused .select__value-container {
	color: #6e707e;
	background-color: #fff;
	border-color: #bac8f3;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(78, 115, 223, 0.25);
}

.navbar .select__control .select__value-container {
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.navbar .select__control .select__indicator,
.navbar .select__control .css-151sz6c-indicatorContainer {
	color: #fff;
}

.navbar .select__control .select__placeholder {
	color: #6e707e;
	font-size: 0.85rem;
}

.wc_download_folder_wrap {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px 15px;
}

.wc_download_folder_wrap .wc_download_folder_col {
	background: #f8f9fc;
	max-width: 400px;
	border-radius: 10px;
	width: 100%;
	padding: 40px 30px;
	box-shadow: 0px 0px 20px #1e30381f;
	text-align: center;
}

.wc_download_folder_wrap .wc_download_folder_col h1 {
	font-size: 24px;
	margin-bottom: 20px;
}

.wc_download_folder_wrap .wc_download_folder_col .wc_download_folder_icon {
	color: #2e59d9;
	display: inline-block;
	font-size: 40px;
	border: 2px solid #1e3038;
	height: auto;
	margin-bottom: 15px;
	line-height: 0;
	padding: 5px 10px;
	border-radius: 5px;
}


form.share-folder .form-group {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

form.share-folder .form-group span {
	color: #000;
	font-size: 14px;
}

form.share-folder .form-group .copy-area {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 38px;
	height: 38px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.1s all ease;
	color: #1e3038;
}

form.share-folder .form-group input.form-control {
	padding-right: 38px;
}

form.share-folder .form-group .copy-area:hover {
	color: #2653d4;
}


.project-scan div#accordionExample .db {
	display: flex;
}

.project-scan div#accordionExample .db i {
	position: unset;
	font-size: 18px;
	color: #1e3038;
	margin: 0 5px;
}

.project-scan div#accordionExample div#headingOne {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.image-share img {
	max-width: 96px;
	margin-bottom: 15px;
}

.wc-nomodel-found-main {
	padding: 100px 0;
}

.wc-nomodel-found-main h1 {
	font-size: 30px;
	margin: 0;
	text-transform: capitalize;
}

.wc-nomodel-found-main .wc-nomodel-found {
	max-width: 150px;
	margin: 20px auto;
}

.wc-nomodel-found-main .wc-nomodel-found img {
	width: 100%;
}

.photo-gallery-box {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
}

/* .photo-gallery-box::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: #0000006b;
    visibility: hidden;
} */

.photo-gallery-box ._30i9K {
	position: relative;
	width: 100%;
	height: 100%;
}

.photo-gallery-box ._30i9K::before {
	content: '';
	padding-top: 100%;
	display: block;
}

.photo-gallery-box ._30i9K img.img-fluid {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: top;
}

.photo-gallery-box:hover::before {
	visibility: visible;
}

.photo-gallery-box-icon {
	position: absolute;
	top: 6px;
	right: 6px;
}

.photo-gallery-box span {
	width: 25px;
	height: 25px;
	border-radius: 5px;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 0;
	margin-bottom: 5px;
	transition: 0.5s all ease;
}

.photo-gallery-box span:nth-child(1) {
	transition-delay: 0.2ms;
}

.photo-gallery-box span:nth-child(2) {
	transition-delay: 0.4ms;
}

.photo-gallery-box span:nth-child(3) {
	transition-delay: 0.6ms;
}

.photo-gallery-box:hover span {
	opacity: 1;
}

.project-view-card h4 {
	font-size: 16px;
	font-weight: 600;
}

.project-view-card h6 {
	font-size: 13px;
}

/*============================ footer css ============================
============================ footer css ============================*/

footer.sticky-footer {
	padding: 2rem 0;
	flex-shrink: 0;
}

footer.sticky-footer .copyright {
	line-height: 1;
	font-size: 0.8rem;
}

body.sidebar-toggled footer.sticky-footer {
	width: 100%;
}


.photo-gallery-box .react-loading-skeleton img {
	opacity: 0;
}